/* eslint-disable */
import React from 'react'

import { Container, Button, Paper, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2' // Grid version 2
import PageFooter from '../components/PageFooter.js'
import { ThemeProvider } from '@mui/system'
import theme, { Colors } from '../styles/theme/'
import { appUtilities, emale } from '../utilities/appUtilities.js'

export default function CheckoutSuccess() {
    const copyright = '\u{00A9}'
    const copyrightText = `${copyright} Copyright `
    const companyNameTM = ` ElectroBraid Fence${appUtilities.specialCharTM}`
    const e = emale()

    return (
        <>
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth="md"
                    sx={{
                        marginTop: { xs: 4, md: 8 },
                    }}
                >
                    <Paper elevation={8}>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12} sx={{ mt: 3 }}>
                                <Typography align="center" variant="h6">
                                    Thank you for your order
                                </Typography>
                            </Grid2>

                            <Grid2
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    align="center"
                                    sx={{ mt: 6 }}
                                >
                                    {
                                        'We appreciate your business. If you have any questions concerning your order, please email '
                                    }
                                </Typography>
                            </Grid2>

                            <Grid2
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    target="_top"
                                    rel="noopener noreferrer"
                                    href={`mailto:${e}`}
                                    color="primary"
                                    sx={{ mt: 2 }}
                                >
                                    <Typography
                                        variant="button"
                                        style={{ fontSize: '0.7rem' }}
                                    >
                                        {e}
                                    </Typography>
                                </Button>
                            </Grid2>

                            <Grid2 xs={12} sx={{ mt: 4, mb: 5, mx: 6 }}>
                                <Typography variant="subtitle1" align="center">
                                    {`Thank you for choosing ${companyNameTM}, America's #1
                                Electric Horse Fence.`}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Paper>
                    <PageFooter />
                </Container>
            </ThemeProvider>
        </>
    )
}
