/* eslint-disable */
/* to remove from grid and place in lower grid total space set productIDNum prefix to '999'  */
export const products = [
    {
        productIDNum: '647',
        productNum: '2',
        productID: 'PBRC1000C2-EB',
        srp: '198.00',
        pkgQty: '1',
        unitLabel: '1,000 ft reel',
        safe: '0',
        href: 'electrobraid-1000-ft-reel-pbrc1000c2-eb',
        displayOrder: 1,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ 1000 ft. Reel (Checkered)',
        description: 'ElectroBraid™ - Americas #1 Horse Fence',
        featureList:
            'Low Cost. 25 Year Warranty. Safe, Secure and Simple to install. Almost No Maintenance. Resilient - bounces back when struck by a panicked horse. Made from UV resistant materials. All copper conductor exceeds the performance of stainless, mixed metal and steel wire.  Meets high standards established for seat belts & parachute harnesses. Braided polyester fiber jacket and polypropylene core.',
        fxInputImageValue:
            'image||437593c5df5f5501992fd1a321c83998d912d369aa387c66db0ed547f0dc9ccd',
        fxInputCodeValue:
            'code||6a1db7a9e889f7eb84394b5b110ac32f003e2a66ad73b78ef4e7d295c3c7ebbb',
        fxInputDescriptValue:
            'name||5d17dc36f7565ca7a2571fd3e596db1b1bf49ffc6bba912245c45a8281af0d08',
        fxInputPriceValue:
            'price||97e5a1dd4e7ccd9137f27c1b41287e61833c51fc85f9172147c872b7592ef33c',
        fxInputQtyValue:
            'quantity||dc991ca027a5e284b0afab1f644260cf260526e496beb6027411d4a23224e4f9||open',
    },
    {
        productIDNum: '645',
        productNum: '22',
        productID: 'PBRC1000W2-EB',
        srp: '198.00',
        pkgQty: '1',
        unitLabel: '1,000 ft reel',
        safe: '0',
        href: 'electrobraid-1000-ft-reel-pbrc1000w2-eb',
        displayOrder: 2,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ 1000 ft. Reel (White)',
        description: 'ElectroBraid™ - Americas #1 Horse Fence',
        featureList:
            'Low Cost. 25 Year Warranty. Safe, Secure and Simple to install. Almost No Maintenance. Resilient - bounces back when struck by a panicked horse. Made from UV resistant materials. All copper conductor exceeds the performance of stainless, mixed metal and steel wire.  Meets high standards established for seat belts & parachute harnesses. Braided polyester fiber jacket and polypropylene core.',
        fxInputImageValue:
            'image||6f79670679f88db111ebc7e108083a75425fa9e7d17ef2f140c6fa314b2a49e9',
        fxInputCodeValue:
            'code||bf565986bf850449390b2ee52ca205581ee912309f147c8d04b7d75251804697',
        fxInputDescriptValue:
            'name||e9d3b66b6c68531209212aaf57b084a97385549aceb6af88404d2305e3869162',
        fxInputPriceValue:
            'price||b305e2fa4aa85a75348503ac799bbcb716f4980b1dcaab9a68de187cc088a0a8',
        fxInputQtyValue:
            'quantity||02df846e0ec5fea9ec91216f9403cbabd19ba62a0405158d4756228b7c7784a4||open',
    },
    {
        productIDNum: '646',
        productNum: '1',
        productID: 'PBRC1000B2-EB',
        srp: '198.00',
        pkgQty: '1',
        unitLabel: '1,000 ft reel',
        safe: '0',
        href: 'electrobraid-1000-ft-reel-pbrc1000b2-eb',
        displayOrder: 3,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ 1000 ft. Reel (Black)',
        description: 'ElectroBraid™ - Americas #1 Horse Fence',
        featureList:
            'Low Cost. 25 Year Warranty. Safe, Secure and Simple to install. Almost No Maintenance. Resilient - bounces back when struck by a panicked horse. Made from UV resistant materials. All copper conductor exceeds the performance of stainless, mixed metal and steel wire.  Meets high standards established for seat belts & parachute harnesses. Braided polyester fiber jacket and polypropylene core.',
        fxInputImageValue:
            'image||8ba69278fa8875147866067592145c50139e51832e42ca73fdcc35175efa6f29',
        fxInputCodeValue:
            'code||00c8bb3991175f45ffe47b507d5acaf01e2fc9dab9f534aae94e6ce9a4bca76b',
        fxInputDescriptValue:
            'name||86b185efa33b48866250f71b8cb63e572c538d104218aea75dd03c7fa2e952f9',
        fxInputPriceValue:
            'price||97620ad0de94fcbcb95b3d72068662db48ebebc0d3647f8beb0e60cb074ea869',
        fxInputQtyValue:
            'quantity||cd9aca9a71d3c13d5c79c375e1e2fe467ee5514136315fd60e4372be1fbd2acb||open',
    },
    {
        productIDNum: '401',
        productNum: '13',
        productID: 'IROLLB10-EB',
        srp: '18.00',
        pkgQty: '10',
        unitLabel: '10/pkg.',
        safe: '6',
        href: 'electrobraid-black-roller-post-insulators-10-pack-irollb10-eb',
        displayOrder: 4,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Roller Post Insulators - Black',
        description:
            'Durable ElectroBraid™ Roller Insulators are engineered for use on corner, end and gate posts. They eliminate friction when tensioning and can withstand greater forces from vertical changes of direction in the fence line peaks and valleys.',
        featureList:
            'Black wood post insulators, Rugged construction to withstand fence line tension, For use on the outside and inside corners, Fastens with nail, j-bolt, most other fasteners',
        fxInputImageValue:
            'image||f5d2faaf369d718935e4a68186c5a637491e980df2d29317c2fbe5462a2acc1e',
        fxInputCodeValue:
            'code||f3dec99cf5434b168bca85816d35edf16edd5d4de61d39bc3cc17eccabc9405a',
        fxInputDescriptValue:
            'name||0e604a86e71dc289932f372e70fd674e29dc68b4797bc4956ceb28dba0002435',
        fxInputPriceValue:
            'price||0a586a69fce8d2eb38de1812386dffbd173402b8daefea708d848efd66b7f552',
        fxInputQtyValue:
            'quantity||1cb018a13e930440983531b3578b784275e61ba4e264fc786437811c0866eec6||open',
    },
    {
        productIDNum: '403',
        productNum: '5',
        productID: 'IWHDCLAWB-EB',
        srp: '14.00',
        pkgQty: '20',
        unitLabel: '20/pkg.',
        safe: '12',
        href: 'electrobraid-black-heavy-duty-line-post-insulators-20-pack-iwhdclawb-eb',
        displayOrder: 5,
        onHandQty: 1000,
        descriptionTitle:
            'ElectroBraid™ Heavy Duty Line Post Insulators - Black',
        description:
            'ElectroBraid™ Heavy-Duty Line Post Insulators are not only for line posts. Use them for brace posts and braces.',
        featureList:
            'Strong glass filled nylon with UV inhibitor for longer life, Use with 1 inch heavy-duty screws to secure line post insulators',
        fxInputImageValue:
            'image||ee18746fe0cd0131871a92d0e1b802ab66fed2eead48fe48d7558cea027a4c10',
        fxInputCodeValue:
            'code||3038f3313bcd2d92ad9aea4883e81e33e2017e3c9e3c846642fc113846a44d85',
        fxInputDescriptValue:
            'name||aa3f6f439b694d16b1bfa4f117d89db543827f8e6400b1da8b66caa065d69c0f',
        fxInputPriceValue:
            'price||f7bcfda07c6df4047fef641eed1d1c1433dc71160b468f02fe0766bcd2aa9216',
        fxInputQtyValue:
            'quantity||52b4369387458dfe7b42f2b720104e6179fb169a37c79b57f41034a0a7dd1f8e||open',
    },
    {
        productIDNum: '419',
        productNum: '9',
        productID: 'ASPLTC10-EB',
        srp: '36.00',
        pkgQty: '10',
        unitLabel: '10/pkg.',
        safe: '4',
        href: 'electrobraid-copper-split-bolt-connector-aspltc10-eb',
        displayOrder: 6,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Copper Split Bolt Connector',
        description:
            'These top quality electrical connectors are used for joining strands of ElectroBraid™ and bridging to the charger and ground rods.',
        featureList: 'Top quality conductor, Extremely durable',
        fxInputImageValue:
            'image||668d2ad40f14ca3f045efac1f28e7cf7accfee1acf172946e8d255bb6b48db1a',
        fxInputCodeValue:
            'code||18e6ef3d1a69818bb146b4ffb05f2455184fc327db2e2c3e0f52059d26f7be7f',
        fxInputDescriptValue:
            'name||d402c5b175a05acbee093d81e31755fd0d7fb1ebaa618dc36f20a4e29a8517e1',
        fxInputPriceValue:
            'price||6c2e815dbbd4ffe8d84e0a7f7199fb24953faf5dbcf522331be157ba25db943b',
        fxInputQtyValue:
            'quantity||eeb0bb014c694eb49e2ddd2e2169c25e6cb28bf5ea0024f9ce49c0ef9a6ffeea||open',
    },
    {
        productIDNum: '399',
        productNum: '12',
        productID: 'ATENSIONKIT-EB',
        srp: '39.00',
        pkgQty: '1',
        unitLabel: '1 kit/pkg.',
        safe: '0',
        href: 'electrobraid-tension-kit-atensionkit-eb',
        displayOrder: 7,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Tension Kit',
        description:
            'Needed to tighten your ElectroBraid™ fence system. A second tensioning kit is required for paddocks of one acre and larger to properly tighten the fence. An additional kit is recommended when tensioning around multiple corners.',
        featureList:
            'Quickly and easily adds tension to your ElectroBraid™ fence, Simple and secure tensioning system, Rugged, sturdy components, Can be reused again and again as needed',
        fxInputImageValue:
            'image||ad54d9f87530ff973ae93faf142e35dd433b2f8456382e677c26012550cdefd9',
        fxInputCodeValue:
            'code||751910cef775442aa1a7510d83fc221fd911aac5c24b0f0f9df7b789aac36144',
        fxInputDescriptValue:
            'name||9b258b063f6bc0c82ae4927ff28de806baf212dbf5a7ed7e493a1c2797db4a43',
        fxInputPriceValue:
            'price||b8968a073fce3cab0a868da26940001124f8590ed4bf06d07128ccc672f40866',
        fxInputQtyValue:
            'quantity||e7515da4d0db3c70b6767427d7d5d89608fd0877e7f55b581cd39e366283d439||open',
    },
    {
        productIDNum: '514',
        productNum: '8',
        productID: 'UGCC200-EB',
        srp: '132.00',
        pkgQty: '1',
        unitLabel: '200 ft/roll',
        safe: '0',
        href: 'electrobraid-high-voltage-insulated-copper-lead-out-wire-ugcc200-eb',
        displayOrder: 8,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Copper Lead Out Wire 200 ft',
        description: 'Provides conductivity for ElectroBraid™ horse fences',
        featureList:
            '14-gauge copper insulated wire withstands up to 15,000 volts, Use with copper split bolts or splicers for non-corroding electrical connections, Designed to be unrolled smoothly from a freely spinning spool',
        fxInputImageValue:
            'image||8e15a7f1098ab8e0ac0eac318e73c79ed65fd5d51b414e85094ed152c3f05118',
        fxInputCodeValue:
            'code||a71a41df52bbaf9ad253b92d3fd6a8bc459e384c30be27d13e2b17e7fd64bdef',
        fxInputDescriptValue:
            'name||fb90a24144b6fd1b57c617f3e9996aca8430fe5ce6f21608b888cb489f8f7913',
        fxInputPriceValue:
            'price||7add8c7794fe5ca53aefb3583e534677b190f7844e5a55d1bdca40e88fede711',
        fxInputQtyValue:
            'quantity||7461a03e844338f45a59792ec9d961dc96933170b29e2288063a83a4910b2908||open',
    },
    {
        productIDNum: '635',
        productNum: '18',
        productID: 'GHWKRW-EB',
        srp: '18.00',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-gate-kit-ghwkrw-eb',
        displayOrder: 9,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Gate Kit',
        description:
            'A complete set of components for a 15 to 20 ft electric fence gate.',
        featureList:
            'Easily electrify a gate opening, A cost-effective gate solution',
        fxInputImageValue:
            'image||e21f765525f4432378e8824c0ed30714dd4f35e2c89b1409e6d2af5ee649bf2f',
        fxInputCodeValue:
            'code||4f4be3c9d416ff88eda38cd1cddea0f5f91cfd3a238a3086e391c33793b199d0',
        fxInputDescriptValue:
            'name||2daae7b3a239a30248f890b30095eab4f73b93453b237711f3b2465b3be67508',
        fxInputPriceValue:
            'price||b9c541b397efcbcb816f56f2f62d42ed70d61bf9fadb3d32a1ebfbdd6b996f4c',
        fxInputQtyValue:
            'quantity||500b8cb797c583f321b596f89c07118f712eaf485c053f278e547990bd62c4b9||open',
    },
    {
        productIDNum: '404',
        productNum: '6',
        productID: 'AS1-EB',
        srp: '7.00',
        pkgQty: '50',
        unitLabel: '50/pkg.',
        safe: '30',
        href: 'electrobraid-heavy-duty-insulator-screw-1-inch-as1-eb',
        displayOrder: 10,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Heavy-Duty Insulator Screw, 1 Inch',
        description:
            '1 inch ElectroBraid™ heavy-duty insulator screws provide years of dependable outdoor use.',
        featureList:
            'Heavy-duty construction, Neoprene Bonded Washer, Rust resistant',
        fxInputImageValue:
            'image||0c1592d9296740a04f8aae197d17ae7f7b00897a245943f89b7a36198e1be756',
        fxInputCodeValue:
            'code||24f0d66d3b99dc2fc0df7cea3dc5fbe9f1479122c4ae00f345a5071e4ef890c3',
        fxInputDescriptValue:
            'name||c6cd20cad6307256179bfb07b757cb284894379c17be517b2aa996daacc0ef6a',
        fxInputPriceValue:
            'price||f97ac554723e8d3fdc1d6ce31196a22e0ed0293f2bf4b33d91e7ff861b7a9cad',
        fxInputQtyValue:
            'quantity||1eeac09fa1ba121961ab4949daef0010193a1c58bf2e16e3e65c83b3d890812c||open',
    },
    {
        productIDNum: '405',
        productNum: '7',
        productID: 'AS25-EB',
        srp: '18.00',
        pkgQty: '50',
        unitLabel: '50/pkg.',
        safe: '0',
        href: 'electrobraid-heavy-duty-insulator-screw-2-1-2-inch-as25-eb',
        displayOrder: 11,
        onHandQty: 1000,
        descriptionTitle:
            'ElectroBraid™ Heavy-Duty Insulator Screw, 2-1/2 Inch',
        description:
            '2 1/2 inch ElectroBraid™ heavy-duty insulator screws provide years of dependable outdoor use.',
        featureList:
            'Heavy-duty construction, Neoprene Bonded Washer, Rust resistant',
        fxInputImageValue:
            'image||64ca6710e6ed09fb8cb301928ff71ade7c14c5b4f307c5857b5549e400d1720d',
        fxInputCodeValue:
            'code||e34c890bc0b44f899ce872a3d895a9e4e01462604e433e96efee3b75a1784a8a',
        fxInputDescriptValue:
            'name||46bd4a31032ee1f3a0ef277744dec3dcebc082d9039ea91a742245efd15d2fe1',
        fxInputPriceValue:
            'price||13bfdb3a0a4962da698e780a10c12ecd1bdc6ce48670e074d538f7316b517033',
        fxInputQtyValue:
            'quantity||c418ab651fffd0d7d4b70068ec3b9ea05fddc11256dc952f4ce1ea0dacd0727f||open',
    },
    {
        productIDNum: '406',
        productNum: '24',
        productID: 'AHDCOS-EB',
        srp: '16.00',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-cut-off-switch-ahdcos-eb',
        displayOrder: 12,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Cut Off Switch',
        description: 'Disables electric fence at convenient locations',
        featureList:
            'Stainless Steel to help prevent corrosion, Helps to locate short circuits',
        fxInputImageValue:
            'image||57366e2406606f83e397f9e8e1027a6aa26b626ca91a68227fc5c3724507d8f9',
        fxInputCodeValue:
            'code||84ede4260677f92ea56e44241377edc7dfbfb2c113e55555755bf386772119e1',
        fxInputDescriptValue:
            'name||0091d0ab2cfceff65f4e2eca54e151f592485b68edd6d38d53b4eb9dfa64ee82',
        fxInputPriceValue:
            'price||0840a36ca6dca4473d13fe00c4bf87d39fc9eca61d6e7a1a83b5ce4ce62869ec',
        fxInputQtyValue:
            'quantity||b4472144e94446eb00f9f2559899d44df6428e2e8d475457007c6bcba108f035||open',
    },
    {
        productIDNum: '407',
        productNum: '25',
        productID: 'ANPC-EB',
        srp: '10.00',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-neutral-plate-connector-anpc-eb',
        displayOrder: 13,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Neutral Plate Connector',
        description:
            'Prevents corrosion between different metals in a wire connection',
        featureList:
            'Allows you to connect ElectroBraid™ to steel, galvanized or aluminum wire, Requires the use of a copper lead-out wire',
        fxInputImageValue:
            'image||fb9f0cd2bcea48ac2c9550a7e2f0dcd958ba410d6b3a1cc349b320cb2b86c39f',
        fxInputCodeValue:
            'code||97185aba51124136bf24292d7c3f3428d4db080cf7540432b211ffd649d2c07f',
        fxInputDescriptValue:
            'name||788991ebfbf3bddd25c1e496a4081f9e35f987f138ab4c80c6215e0dc3548b9b',
        fxInputPriceValue:
            'price||9ead8e291fff881e6a69dedb220d2ce9dd78d3d92e82b9a51ce23da92733a566',
        fxInputQtyValue:
            'quantity||ba23ead33fbaf546d151ec68449ac6af9370587942604ecc3b693ffa146fedaf||open',
    },
    {
        productIDNum: '411',
        productNum: '26',
        productID: 'WS3-EB',
        srp: '8.00',
        pkgQty: '3',
        unitLabel: '3/pkg.',
        safe: '0',
        href: 'electrobraid-warning-sign-ws3-eb',
        displayOrder: 14,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Warning Signs',
        description: 'Alert people to be aware of electric fence',
        featureList: 'Highly visible, Easy to attach',
        fxInputImageValue:
            'image||b090ad8278710a5f16b5a80a76a110da586b1665f82b2da26d184cd8a6e2f602',
        fxInputCodeValue:
            'code||fb31527768ceaacda6c282c0332f1a916c54dc073a911a4be5bff37dff5378f1',
        fxInputDescriptValue:
            'name||8e8c781197d7061672cb308e3554a3f569e6d0f2ca3c83d16fff894a857f47c9',
        fxInputPriceValue:
            'price||bf99e23bdca12269c99f3e40a53e675171913be9e018a5c52458572603defd56',
        fxInputQtyValue:
            'quantity||c871eec62bc774a2128b7085d5dd1440b7fc560dd72745efda1ad0ae91dab8ae||open',
    },
    {
        productIDNum: '553',
        productNum: '23',
        productID: 'CGRC3-EB',
        srp: '21.00',
        pkgQty: '3',
        unitLabel: '3/pkg.',
        safe: '0',
        href: 'electrobraid-copper-ground-rod-clamp-cgrc3-eb',
        displayOrder: 17,
        onHandQty: 1000,
        descriptionTitle: 'ElectroBraid™ Copper Ground Rod Clamps (3/pk)',
        description:
            'Clamp maintains a secure connection between the lead out wire and the ground rod',
        featureList:
            'Brass coated to avoid galvanic corrosion with the copper, For use with 5/8″ – 1/2″ ground rods in your electric fence grounding system',
        fxInputImageValue:
            'image||c6826ff256dbd0d655da1155120105cdc1604c6fe9ade077fa6c7f75ca315855',
        fxInputCodeValue:
            'code||a3f61c50f0db27a3ba485d14f2ec8a7700d8167f680a9cd34d9de6e3d70aa280',
        fxInputDescriptValue:
            'name||172b101fb04df59ed3563cc743f4cbdd80d6bb9a7d22b86ba6e9e29ceb9eee52',
        fxInputPriceValue:
            'price||6b8029a6e3f36daad25a7b162c990d1381252cdbbaa3d1ef969304bcbe6a8fa3',
        fxInputQtyValue:
            'quantity||7b713bc810d1800f988f1b2c9dea9743b42fa328d1e2f1e444360b383ae9f7a2||open',
    },
    // TODO: 07/01/24 **** fix voltmeter when asjusting price of reels
    // TODO: 09/14/24 **** when next redo change all the TM to trademark R in description and descriptionTitle
    {
        productIDNum: '648',
        productNum: '19',
        productID: 'DVM-EB',
        srp: '42.00',
        pkgQty: '1',
        unitLabel: '1/pkg.',
        safe: '0',
        href: 'electrobraid-2411-digital-volt-meter',
        displayOrder: 18,
        onHandQty: 1000,
        descriptionTitle: 'Digital Volt Meter',
        description:
            'Get Accurate Read Out up to 9,999 volts. Use the Digital Volt Meter to measure fence line and fence charger voltage.',
        featureList:
            'Extremely sensitive and accurate. Powered by 9-volt battery (included)',
        fxInputImageValue:
            'image||eb52edfb7c212c0bcd187e2a79d9ff699bd458bb67265e964bcd98bea699a5c6',
        fxInputCodeValue:
            'code||30afc473edd27f6427e38a516f386af18c6ed76befe5c0b8fac0c051780d95ce',
        fxInputDescriptValue:
            'name||c2eeaca4e8488fcc33132e59255f924ee0c39aa5504c3ef4001e4010f42aac5b',
        fxInputPriceValue:
            'price||566f46f608521f238913e2bc88f792044c35dae148a0ceb1c3e75e1d300eb633',
        fxInputQtyValue:
            'quantity||b77ee255d766929e5f189685de410f3fb6ec182efad6418d587a3e2f77c9119c||open',
    },

    // ********************************************************************************************************************
    // ********************************************************************************************************************
    
    //                                  PRODUCTS USED FOR ESTIMATION REPORTS

    
    // ********************************************************************************************************************
    // ********************************************************************************************************************
    // ********************************************************************************************************************
    // ********************************************************************************************************************
    // ********************************************************************************************************************

    {
        productIDNum: '99911',
        productNum: '99911',
        productID: 'GRSC8-EB',
        srp: '0.00',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 91,
        onHandQty: 0,
        descriptionTitle: 'Copper Ground Rods',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputPriceValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99912',
        productNum: '99912',
        productID: 'ITB-EB',
        srp: '0.00',
        pkgQty: '1',
        unitLabel: '',
        safe: '6',
        href: '',
        displayOrder: 92,
        onHandQty: 0,
        descriptionTitle: 'T-Post Insulators',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputPriceValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99913',
        productNum: '99913',
        productID: 'ITCPB-EB',
        srp: '0.00',
        pkgQty: '1',
        unitLabel: '',
        safe: '4',
        href: '',
        displayOrder: 93,
        onHandQty: 0,
        descriptionTitle: 'T-Post Cap Insulators',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputPriceValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99914',
        productNum: '99914',
        productID: 'EAC50M-Z',
        srp: '0.00',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 94,
        onHandQty: 0,
        descriptionTitle: '50 Mile (2 Joule) AC Low Impedance Energizer',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputPriceValue: '',
        fxInputQtyValue: '',
    },
    {
        productIDNum: '99915',
        productNum: '99915',
        productID: 'EAC75M-Z',
        srp: '0.00',
        pkgQty: '1',
        unitLabel: '',
        safe: '0',
        href: '',
        displayOrder: 95,
        onHandQty: 0,
        descriptionTitle: '75 Mile (4 Joule) AC Low Impedance Energizer',
        description: '',
        featureList: '',
        fxInputImageValue: '',
        fxInputCodeValue: '',
        fxInputDescriptValue: '',
        fxInputPriceValue: '',
        fxInputQtyValue: '',
    },
]
