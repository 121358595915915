import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Colors } from '../theme'

export const BannerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '30px 0 30px 0',
    background: Colors.companyColors.eb_bolt_service,
    border: `2px solid ${Colors.companyColors.eb_text}`,
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    // backgroundImage: `url(/images/banner/banner.png)`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
}))

export const BannerContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 420,
    padding: '30px',
}))

export const BannerImage = styled('img')(({ src, theme }) => ({
    src: `url(${src})`,
    // backgroundImage: `url(${src})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    width: '440px',
    height: '440px',
    marginTop: '30px',
    border: '2px solid #000',
    borderRadius: '6px',
    // [theme.breakpoints.down('xs')]: {
    //     width: '140px',
    //     height: '140px',
    // },
    [theme.breakpoints.down('md')]: {
        width: '480px',
        height: '480px',
        marginTop: '20px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '360px',
        height: '360px',
    },
}))

export const BannerTitle = styled(Typography)(({ theme }) => ({
    lineHeight: 1.25,
    fontSize: '72px',
    marginBottom: '.6em',
    textShadow: '1px 1px .25px #006e64 , 1px 1px .25px #006e64',
    color: Colors.companyColors.eb_bkgrnd,

    [theme.breakpoints.down('md')]: {
        fontSize: '60px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
    },
}))

// sx={{

//     // fontFamily: 'Roboto',
//     textAlign: 'center',
//     letterSpacing: '2px',
//     // lineHeight: '2rem',
//     // textTransform: 'uppercase',

//     borderRadius: '8px',

//     // width: 'fit-content',
//     backgroundColor: '#fef4e9',
//     padding: '1rem 8rem',
// }}

// fontSize: '3.5rem',

export const BannerDescription = styled(Typography)(({ theme }) => ({
    marginTop: '5px',
    fontSize: '1.25rem',
    lineHeight: 1.25,
    letterSpacing: 1.25,
    marginBottom: '1.25em',
    // textShadow: '2px 2px black',
    // fontWeight: 700,
    color: ' #fef4e9',
    // border: '2px solid #b8956f',
    textShadow: '1px 1px .25px #000 , 1px 1px .25px #000',
    [theme.breakpoints.down('md')]: {
        lineHeight: 1.15,
        letterSpacing: 1.15,
        marginBottom: '1.5em',
    },
}))

// export const BannerShopButton = styled(Button, {
//     // Configure which props should be forwarded on DOM
//     shouldForwardProp: (prop) => prop !== 'color',
//     name: 'MyShopButton',
//     slot: 'Root',
//     // We are specifying here how the styleOverrides are being applied based on props
//     overridesResolver: (props, styles) => [
//         styles.root,
//         props.color === 'primary' && styles.primary,
//         props.color === 'secondary' && styles.secondary,
//     ],
// })(({ theme }) => ({
//     padding: '20px 0px',
//     color: Colors.white,
//     fontWeight: 'bold',
//     fontSize: '16px',
//     [theme.breakpoints.down('sm')]: {
//         padding: '10px 0px',
//         fontSize: '14px',
//     },
// }))
