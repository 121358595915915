import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { config } from '../utilities/constants'
export default function NoMatch() {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate(`/`)
        }, 2800)
    }, [navigate])

    return (
        <>
            <h1>Sorry, there is no page for this URL</h1>
            <p>The page you requested does not exist.</p>
            {/* <p>We&lsquo;ll send you back to the home page.</p> */}
        </>
    )
}
// TODO: 05/13/24 get pretty
// https://creativecommons.org/licenses/by-sa/2.0/
//https://www.flickr.com/photos/pmarkham/2795321710
