import React from 'react'
import { Stack, Box, Avatar, Typography, Paper } from '@mui/material'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

import { useShoppingCart } from '../context/ShoppingCartContext'
import IncDec from './ui/incdec'
import { config } from '../utilities/constants'
import { getMoneyFormat, getLineItemTotal } from '../utilities/money'

import { Colors } from '../styles/theme'

export function CartItem({ productIDNum, quantity, storeItems }) {
    const { incrItemQty, decrItemQty, removeFromCart } = useShoppingCart()

    const handlerIncrItemQty = () => incrItemQty(productIDNum)
    const handlerDecrItemQty = () => decrItemQty(productIDNum)

    const item = storeItems.find((i) => i.productIDNum === productIDNum)
    if (item == null) return null
    const image = item.href

    const imagePath = config.img_path_cart

    return (
        <>
            <Paper elevation={3} sx={{ mb: 1 }}>
                <Box
                    display="flex"
                    flexDirection={'row'}
                    sx={{
                        pt: 2,
                        pb: 2,
                        border: `0.5px solid ${Colors.companyColors.eb_bolt}`,
                    }}
                    alignItems="start"
                    justifyContent={'flex-start'}
                >
                    <Avatar
                        src={`${imagePath}${image}.jpg`}
                        sx={{ width: 128, height: 128, ml: 2, mr: 4, mt: 2.5 }}
                    />

                    <Box
                        display={'flex'}
                        width={'100%'}
                        flexDirection={'column'}
                        sx={{
                            pt: 0.5,
                            pb: 2,
                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            sx={{ pt: 2, pb: 2.5 }}
                            justifyContent={'space-between'}
                        >
                            <Typography variant="h6">
                                {item.descriptionTitle}
                            </Typography>

                            <DeleteForeverRoundedIcon
                                fontSize="large"
                                sx={{ mx: 5, mt: -0.2 }}
                                onClick={() =>
                                    removeFromCart(item.productIDNum)
                                }
                            />
                        </Box>

                        <Box>
                            <IncDec
                                qty={quantity}
                                onIncrItemQty={handlerIncrItemQty}
                                onDecrItemQty={handlerDecrItemQty}
                            />
                            <Stack
                                display={'flex'}
                                alignItems="center"
                                justifyContent={'space-between'}
                                sx={{ mt: 1.0 }}
                                direction="row"
                            >
                                <Stack>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: { xs: '110%' } }}
                                    >
                                        Unit price - {getMoneyFormat(item.srp)}
                                    </Typography>
                                    <Typography variant="captionText">
                                        {item.unitLabel}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={'column'}
                                    sx={{ mt: -0.5, mr: 3 }}
                                >
                                    <Typography
                                        variant="h6"
                                        align="right"
                                        sx={{ ml: 1, mr: 2.5, mt: -0.0 }}
                                    >
                                        Subtotal
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        align="right"
                                        // color={'black'}
                                        sx={{ ml: 1, mr: 2.5, mt: -0.0 }}
                                    >
                                        {getLineItemTotal(quantity, item.srp)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}
