import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
    Link,
    styled,
} from '@mui/material'
import { useUIContext } from '../../context/ui'
import CloseIcon from '@mui/icons-material/Close'
import { DrawerCloseButton } from '../../styles/appbar'
import { Colors } from '../../styles/theme'

const MiddleDivider = styled((props) => (
    <Divider variant="middle" {...props} />
))``

export default function AppDrawer() {
    const { drawerOpen, setDrawerOpen } = useUIContext()

    return (
        <>
            {drawerOpen && (
                <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
                    <CloseIcon
                        sx={{
                            fontSize: '2.5rem',
                            color: Colors.primary,
                        }}
                    />
                </DrawerCloseButton>
            )}
            <Drawer open={drawerOpen}>
                <List>
                    <ListItemButton>
                        <ListItemText>
                            {' '}
                            <Link
                                href="https://www.electrobraid.com/"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Read about Privacy Policy"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Home
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            {' '}
                            <Link
                                href="https://www.electrobraid.com/calc"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Visit Fence Material Estimator App"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Fence Materials Estimator
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            {' '}
                            <Link
                                component={RouterLink}
                                to="/"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Visit Products section"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Products
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            {' '}
                            <Link
                                component={RouterLink}
                                to="/cart"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Visit your cart items"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Cart Review
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            <Link
                                href="https://www.electrobraid.com/privacy.html"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Read about Privacy Policy"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Privacy &amp; Policy
                            </Link>
                            {/* </Typography> */}
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            <Link
                                component={RouterLink}
                                to="/info/terms_conditions"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Learn about Terms and Conditions"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Terms &amp; Conditions
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            <Link
                                component={RouterLink}
                                to="/info/shipping"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Learn about Shipping Policies"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Shipping Policy
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            <Link
                                component={RouterLink}
                                to="/info/return_policy"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Learn about Return Policy"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Return Policy
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            {' '}
                            <Link
                                component={RouterLink}
                                to="/info/card_security"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="Learn about Terms and Conditions"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Cardholder Security Policy
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                    <ListItemButton>
                        <ListItemText>
                            <Link
                                component={RouterLink}
                                to="/info/contact_us"
                                color={Colors.secondary}
                                underline="none"
                                aria-label="How to Contact Us"
                                onClick={() => setDrawerOpen(false)}
                            >
                                Contact Us
                            </Link>
                        </ListItemText>
                    </ListItemButton>
                    <MiddleDivider />
                </List>
            </Drawer>
        </>
    )
}
