import React from 'react'
import { Product, ProductImage } from '../../styles/product'
import { Stack, Typography, Box, Divider, Paper } from '@mui/material'
import useDialogModal from '../../hooks/useDialogModal'
import ProductDetail from '../productdetail'
import ProductMeta from './ProductMeta'
import { config } from '../../utilities/constants'
import IncDec from '../ui/incdec'
import { useShoppingCart } from '../../context/ShoppingCartContext'

export default function SingleProduct({ product, matches }) {
    const { getItemQty, incrItemQty, decrItemQty } = useShoppingCart()
    const [ProductDetailDialog, showProductDetailDialog] =
        useDialogModal(ProductDetail)
    const qty = getItemQty(product.productIDNum)
    const handlerIncrItemQty = () => incrItemQty(product.productIDNum)
    const handlerDecrItemQty = () => decrItemQty(product.productIDNum)

    return (
        <>
            <Product>
                <Paper elevation={6} sx={{ padding: '0 20px 0 0' }}>
                    <ProductImage
                        onClick={() => showProductDetailDialog()}
                        src={`${config.img_path}${product.href}.jpg`}
                    />
                </Paper>
            </Product>

            <Stack
                justifyContent={'space-between'}
                alignItems={'center'}
                direction={'column'}
                sx={{ minHeight: { xs: '20rem', sm: '17.5rem' } }}
            >
                <Box>
                    <ProductMeta product={product} matches={matches} />
                </Box>

                <Box sx={{ mb: 4 }}>
                    <Stack sx={{ mb: 3 }}>
                        <Typography
                            variant={matches ? 'h6' : 'body1'}
                            align="center"
                            sx={{ mb: 0 }}
                        >
                            ${product.srp}
                        </Typography>
                        <Typography variant={'captionText'} align="center">
                            {product.unitLabel}
                        </Typography>
                    </Stack>

                    <IncDec
                        qty={qty}
                        onIncrItemQty={handlerIncrItemQty}
                        onDecrItemQty={handlerDecrItemQty}
                    />
                </Box>
            </Stack>
            <Divider orientation="horizontal" variant="top" flexItem />
            <ProductDetailDialog product={product} />
        </>
    )
}
