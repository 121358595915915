import { Container } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

export default function Landing() {
    return (
        <Container maxWidth="lg">
            <Outlet />
        </Container>
    )
}
