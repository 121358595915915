import Dinero from 'dinero.js'

function strToIntPennies(currencyStr) {
    let currencystrNoComma = currencyStr
        .split('')
        .filter((element) => element !== ',')
        .filter((element) => element !== '$')
        .join('')
    let currencyFloat = parseFloat(currencystrNoComma)
    let currencyHundred = currencyFloat * 100 // Dinero counts cents
    let currencyCeiling = Math.ceil(currencyHundred)
    // @ts-ignore
    return parseInt(currencyCeiling, 10)
}

function intPenniesToStr(intPennies) {
    return Dinero({ amount: intPennies }).toFormat('$0,0.00')
}
function intPenniesToStrWithCurrency(intPennies, currencyType) {
    const strValue = Dinero({ amount: intPennies }).toFormat('$0,0.00')
    return `${strValue} ${currencyType.toUpperCase()}`
}
// (int, '$9.99')
function getLineItemTotal(qty, srpString) {
    let qtyInt = parseInt(qty, 10)
    let srpHundredInt = strToIntPennies(srpString)

    let srpDollars = Dinero({ amount: srpHundredInt })
        .multiply(qtyInt)
        .toFormat('$0,0.00')

    return srpDollars
}

// ('9.99')
function getMoneyFormat(str) {
    const dinero = strToIntPennies(str)
    return Dinero({ amount: dinero }).toFormat('$0,0.00')
}

function getPercentInIntPennies(total, percentage) {
    // returns an array of two Dinero objects
    // the first one with an amount of 25
    // the second one with an amount of 75
    let res = Dinero({ amount: total })
        .allocate([percentage, 100 - percentage])[0]
        .toRoundedUnit(1)

    return (res * 100).toFixed(0)
}

export {
    strToIntPennies,
    intPenniesToStr,
    intPenniesToStrWithCurrency,
    getLineItemTotal,
    getMoneyFormat,
    getPercentInIntPennies,
}

// TODO: 05/20/23 develop unit test

/* 
let val = getPercentInIntPennies(10000, 20)
val

let val1 = getPercentInIntPennies(9700, 20)
val1

let val2 = getPercentInIntPennies(9550, 20)
val2

let val3 = getPercentInIntPennies(91314, 20)
val3


// ***********
let g = getLineItemTotal('4', '11')
g

let stringForDinero = strToIntPennies('12,212.12')
stringForDinero

// ***********

const inttop = intPenniesToStr(1200)
inttop

// ***********

let stringsForDineroClass = [
    '16.15',
    '12',
    '12.0',
    '12,000',
    '1.2',
    '.12',
    '.012',
    '$12',
    '$12.0',
].map((item) => {
    return strToIntPennies(item)
})
stringsForDineroClass

// ***********

// ***********

let MoneyFormat = getMoneyFormat('99.8')
MoneyFormat



 */
