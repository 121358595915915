import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import { emale } from '../../utilities/appUtilities.js'

export default function ContactUs() {
    const e = emale()
    return (
        <>
            <Typography variant="h4" sx={{ mt: 3 }}>
                Contact Us
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 },
                    mt: 2,
                }}
            >
                If you have any questions or concerns please contact
                ElectroBraid<sup>&#xAE;</sup> Fence at{' '}
            </Typography>
            <Box
                display={'flex'}
                justifyContent={'flex-start'}
                sx={{ ml: 12, my: 3 }}
            >
                <Button
                    variant="contained"
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${e}`}
                    color="primary"
                    sx={{ ml: 1 }}
                >
                    <Typography variant="button" style={{ fontSize: '0.7rem' }}>
                        {e}
                    </Typography>
                </Button>
            </Box>
            <Typography
                variant="body1"
                sx={{
                    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 },
                    mt: 0,
                    mb: 5,
                }}
            >
                or call 1 855 EB-FENCE (1 855 323 3623).
            </Typography>
        </>
    )
}
