const prod = {
    img_path: '/shop/images/cards/',
    img_path_cart: '/shop/images/cards/',
    // url: {
    //     success: `https://www.electrobraid.com/calc/pg_success?id={CHECKOUT_SESSION_ID}`,
    // },

    // inv_json_bom1: 'https://api.electrobraid.com/inv_dtl1',
    // inv_json_bom2: 'https://api.electrobraid.com/inv_dtl2',
}
const dev = {
    img_path: '/shop/images/cards/',
    img_path_cart: '/shop/images/cards/',
    // url: {
    //     success: `http://localhost:3011/calc/pg_success?id={CHECKOUT_SESSION_ID}`,
    // },
    // inv_json_bom1: 'https://api.electrobraid.com/inv_dtl1',
    // inv_json_bom2: 'https://api.electrobraid.com/inv_dtl2',
}
export const config = process.env.NODE_ENV === 'development' ? dev : prod
