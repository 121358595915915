/* eslint-disable */
import React from 'react'
import { useLocation } from 'react-router-dom'
import Appbar from '../components/appbar/Appbar'
import { ThemeProvider } from '@mui/system'
import theme, { Colors } from '../styles/theme/'
import Products from '../components/products/Products'
import Banner from '../components/banner'
import {
    Button,
    Box,
    Container,
    Paper,
    Typography,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Collapse,
    CardActions,
    IconButton,
    Stack,
} from '@mui/material'
import { UIProvider } from '../context/ui/'
import Grid2 from '@mui/material/Unstable_Grid2' // Grid version 2
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Footer from '../components/footer'
import PageFooter from '../components/PageFooter.js'
import TermsAndConditions from '../components/boilerPlate/termsConditions'
import ShipPolicy from '../components/boilerPlate/shipPolicy'
import ReturnPolicy from '../components/boilerPlate/returnPolicy'
import ContactUs from '../components/boilerPlate/contactUs'
import CardSecurity from '../components/boilerPlate/cardsecurity'
import AppDrawer from '../components/drawer'
// import { useUIContext } from '../../context/ui'
import { useParams } from 'react-router-dom'
// import { UIProvider } from '../context/ui/'
export default function BoilerPlateArray() {
    // const location = useLocation()
    // const infoType = location.state.infoType
    // const { setDrawerOpen } = useUIContext()
    const { infoType } = useParams()

    const info = {
        terms_conditions: <TermsAndConditions />,
        shipping: <ShipPolicy />,
        return_policy: <ReturnPolicy />,
        contact_us: <ContactUs />,
        card_security: <CardSecurity />,
    }
    const res = info[infoType]

    return (
        <ThemeProvider theme={theme}>
            <Container
                maxWidth="lg"
                sx={{
                    // marginTop: { xs: 4, md: 8 },
                    background: theme.palette.primary.main,
                    border: `solid 1px ${Colors.secondary}`,
                }}
            >
                <Stack>
                    <UIProvider>
                        <Appbar />
                        <Banner />
                        {/*    <Promotions />
                          <SearchBox /> */}
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            sx={{ p: 4 }}
                        >
                            <Typography variant="h4">{res}</Typography>
                        </Box>
                        <Footer />
                        <AppDrawer
                        // onClick={() => setDrawerOpen(false)}
                        ></AppDrawer>
                    </UIProvider>
                </Stack>
                <PageFooter />
            </Container>
        </ThemeProvider>
    )
}
