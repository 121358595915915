import React from 'react'
import { Typography, Link } from '@mui/material'
import { Colors } from '../../styles/theme'
import { appUtilities } from '../../utilities/appUtilities.js'

function privacyLink(text, path, label) {
    return (
        <Link
            href={path}
            color={Colors.secondary}
            variant="body1"
            sx={{ fontWeight: 600 }}
            // underline="none"
            aria-label={label}
        >
            {text}
        </Link>
    )
}

export default function CardSecurity() {
    const regTM = appUtilities.regTrademark
    const auth1 = `You can shop at ElectroBraid`
    const auth1b = ` Fence with confidence. We have
  partnered with `
    const auth2 = privacyLink(
        `Authorize.Net`,
        `http://www.authorize.net`,
        `Visit Authorize.Net the payment gateway`,
    )

    const auth3 = `, a leading payment gateway since 1996, to accept credit cards
  safely and securely for our customers. The Authorize.Net Payment
  Gateway manages the complex routing of sensitive customer
  information through the credit card processing networks. View an
 online `
    const auth4 = privacyLink(
        `payments explanation `,
        `https://www.authorize.net/resources/how-payments-work.html`,
        `Visit Authorize.Net "How Payments Are Made" web page`,
    )

    const auth5 = ` to see
      how it works. The company adheres to strict industry standards
      for payment processing, including:`
    const auth5b = (
        <Typography sx={{ ml: 6, mt: 2, mb: 2 }}>
            <Typography sx={{ mt: 1 }}>
                - 128-bit Secure Sockets Layer (SSL) technology for secure
                Internet Protocol (IP) transactions.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                - Industry leading encryption hardware and software methods and
                security protocols to protect customer information.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                - Compliance with the Payment Card Industry Data Security
                Standard (PCI DSS).
            </Typography>
        </Typography>
    )

    const auth5c = ` For additional information regarding the privacy of your
    sensitive cardholder data, please read the `
    const auth6 = privacyLink(
        `Authorize.Net Privacy Policy`,
        `http://www.authorize.net/company/privacy`,
        `Visit Authorize.Net Privacy Statement`,
    )

    const auth6b = `. Our `
    const auth7 = privacyLink(
        `https://www.electrobraid.com/`,
        `https://www.electrobraid.com/`,
        `Visit ElectroBraid fence front page`,
    )

    const auth8 = ` is registered with the Authorize.Net Verified Merchant Seal program.`

    const auth = [
        auth1,
        regTM,
        auth1b,
        auth2,
        auth3,
        auth4,
        auth5,
        auth5b,
        auth5c,
        auth6,
        auth6b,
        auth7,
        auth8,
    ]
    const foxy1 = `In order to minimize the risk of security incidents, ElectroBraid`

    const foxy1b = ` Fence fully
    outsources all payment processing to `

    const foxy2 = privacyLink(
        'Foxy.io',
        'https://www.foxy.io/',
        ' Visit Ecommerce platform Foxy.io',
    )

    const foxy3 = `. Foxy.io is PCI DSS (Payment Card Industry Data Security Standard) Compliant and as a Level 1 Service Provider is listed on both Visa and MasterCard's global registries. Anytime you submit payment
    information via ElectroBraid`

    const foxy4 = ` Fence's website, you are submitting through
    Foxy.io's secure infrastructure. ElectroBraid`

    const foxy5 = ` Fence retains only the last 4 digits, the card type, and the expiration date of your payment details.`

    const foxy = [
        foxy1,
        regTM,
        foxy1b,
        foxy2,
        foxy3,
        regTM,
        foxy4,
        regTM,
        foxy5,
    ]

    return (
        <>
            <Typography variant="h4" sx={{ mt: 3 }}>
                Credit Cardholder Data Security
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                Credit Card Payment Gateway - Authorize.Net
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 },
                    mt: 2,
                }}
            >
                {auth}
            </Typography>
            <Typography variant="h6" sx={{ mt: 4 }}>
                Ecommerce Platform - FoxyCart
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 },
                    mt: 2,
                }}
            >
                {foxy}
            </Typography>
        </>
    )
}
