import React from 'react'
import { Typography } from '@mui/material'
import { ProductMetaWrapper } from '../../styles/product'
export default function ProductMeta({ product }) {
    return (
        <ProductMetaWrapper>
            <Typography variant="h6" sx={{ my: 2, textAlign: 'center' }}>
                {product.descriptionTitle}
            </Typography>
        </ProductMetaWrapper>
    )
}
