import React from 'react'
import { Typography } from '@mui/material'
import { Link } from '@mui/material'
import { Colors } from '../../styles/theme'
export default function TermsAndConditions() {
    const sx1 = {
        ml: { xs: 0, sm: 2, md: 8 },
        mr: { xs: 1, sm: 5, md: 20 },
        mt: 2,
    }
    const sx2 = {
        ml: { xs: 2, sm: 4, md: 12 },
        mr: { xs: 2, sm: 10, md: 20 },
        mb: 0.5,
    }

    return (
        <>
            <Typography variant="h4" sx={{ mt: 3 }}>
                Terms and Conditions
            </Typography>
            <Typography variant="body1" sx={sx1}>
                These terms and conditions outline the rules and regulations for
                the use of the ElectroBraid<sup>&#xAE;</sup> Fence website,
                located at https://electrobraid.com. By accessing this website,
                we assume you accept these terms and conditions. Do not continue
                to use ElectroBraid<sup>&#xAE;</sup> Fence if you do not agree
                to accept all of the terms and conditions stated on this page.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                1. License to Use Website
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Unless otherwise stated, ElectroBraid<sup>&#xAE;</sup> Fence
                and/or its licensors own the intellectual property rights for
                all material on ElectroBraid<sup>&#xAE;</sup> Fence. All
                intellectual property rights are reserved. You may access this
                from ElectroBraid<sup>&#xAE;</sup> Fence for your own personal
                use subjected to restrictions set in these terms and conditions.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                2. Restrictions
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    ml: { xs: 0, sm: 2, md: 8 },
                    mr: { xs: 1, sm: 5, md: 20 },
                    mt: 2,
                    mb: 2,
                }}
            >
                You are specifically restricted from all of the following:
            </Typography>
            {/* <Typography variant="subtitle1" sx={{ ml: 16, mr: 20 }}> */}
            <Typography variant="subtitle1" sx={sx2}>
                - publishing any website material in any other CardMedia
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                {' -  '}
                selling, sublicensing and/or otherwise commercializing any
                website material
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                - publicly performing and/or showing any website material
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                {' -  '}
                using this website in any way that is or may be damaging to this
                website
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                {' -  '}
                using this website in any way that impacts user access to this
                website
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                - using this website contrary to applicable laws and
                regulations, or in any way may cause harm to the website, or to
                any person or business entity
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                {' -  '}
                engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this website
            </Typography>
            <Typography variant="subtitle1" sx={sx2}>
                {' -  '}
                using this website to engage in any advertising or marketing.
            </Typography>
            <Typography variant="h6" sx={{ mt: 4 }}>
                3. Your Content
            </Typography>
            <Typography variant="body1" sx={sx1}>
                In these terms and conditions, “Your Content” shall mean any
                audio, video text, images or other material you choose to
                display on this website. By displaying Your Content, you grant
                ElectroBraid<sup>&#xAE;</sup> Fence a non-exclusive, worldwide
                irrevocable, sub licensable license to use, reproduce, adapt,
                publish, translate and distribute it in any and all media.
            </Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
                4. Your Privacy
            </Typography>
            <Typography variant="body1" sx={sx1}>
                Please read
                <Link
                    href="https://www.electrobraid.com/privacy.html"
                    color={Colors.secondary}
                    aria-label="Read about Privacy Policy"
                    variant="h6"
                    sx={{ ml: 1 }}
                >
                    Privacy Policy
                </Link>
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                5. No warranties
            </Typography>
            <Typography variant="body1" sx={sx1}>
                This website is provided “as is,” with all faults, and
                ElectroBraid<sup>&#xAE;</sup> Fence expresses no representations
                or warranties, of any kind related to this website or the
                materials contained on this website. Also, nothing contained on
                this website shall be interpreted as advising you.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                6. Limitation of liability
            </Typography>
            <Typography variant="body1" sx={sx1}>
                In no event shall ElectroBraid<sup>&#xAE;</sup> Fence, nor any
                of its officers, directors and employees, shall be held liable
                for anything arising out of or in any way connected with your
                use of this website whether such liability is under contract.
                ElectroBraid<sup>&#xAE;</sup>
                Fence, including its officers, directors and employees shall not
                be held liable for any indirect, consequential or special
                liability arising out of or in any way related to your use of
                this website.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                7. Indemnification
            </Typography>
            <Typography variant="body1" sx={sx1}>
                You hereby indemnify to the fullest extent ElectroBraid
                <sup>&#xAE;</sup> Fence from and against any and/or all
                liabilities, costs, demands, causes of action, damages and
                expenses arising in any way related to your breach of any of the
                provisions of these terms.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                8. Severability
            </Typography>
            <Typography variant="body1" sx={sx1}>
                If any provision of these terms is found to be invalid under any
                applicable law, such provisions shall be deleted without
                affecting the remaining provisions herein.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                9. Variation of Terms
            </Typography>
            <Typography variant="body1" sx={sx1}>
                ElectroBraid<sup>&#xAE;</sup> Fence is permitted to revise these
                terms at any time as it sees fit, and by using this website you
                are expected to review these terms on a regular basis.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                10. Assignment
            </Typography>
            <Typography variant="body1" sx={sx1}>
                ElectroBraid<sup>&#xAE;</sup> Fence is allowed to assign,
                transfer, and subcontract its rights and/or obligations under
                these terms without any notification. However, you are not
                allowed to assign, transfer, or subcontract any of your rights
                and/or obligations under these terms.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                11. Entire Agreement
            </Typography>
            <Typography variant="body1" sx={sx1}>
                These terms constitute the entire agreement between ElectroBraid
                <sup>&#xAE;</sup> Fence and you in relation to your use of this
                website and supersede all prior agreements and understandings.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                12. Governing Law & Jurisdiction
            </Typography>
            <Typography variant="body1" sx={sx1}>
                These terms will be governed by and interpreted in accordance
                with the laws of the jurisdiction of ElectroBraid
                <sup>&#xAE;</sup> Fence Location, and you submit to the
                non-exclusive jurisdiction of the state and federal courts
                located in ElectroBraid<sup>&#xAE;</sup> Fence Location for the
                resolution of any disputes.
            </Typography>
        </>
    )
}
