import React from 'react'

const regTrademark = (
    <b>
        <sup>{'\u{00AE}'}</sup>
    </b>
)
export const appUtilities = {
    regTrademark: regTrademark,
}

const emaleBuild = () =>
    ['in', 'f', 'o@', 'el', 'ectr', 'obra', 'id.c', 'om'].join('')

export function emale() {
    return emaleBuild()
}
