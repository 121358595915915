import React from 'react'
import { Badge, Divider, ListItemButton, ListItemIcon } from '@mui/material'
import {
    ActionIconsContainerDesktop,
    ActionIconsContainerMobile,
    MyList,
} from '../../styles/appbar'
// import PersonIcon from '@mui/icons-material/Person'
// import FavoriteIcon from '@mui/icons-material/Favorite'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Colors } from '../../styles/theme'
import { useShoppingCart } from 'context/ShoppingCartContext'
import { Link as RouterLink } from 'react-router-dom'
// import { config } from '../../utilities/constants'
export default function Actions({ matches }) {
    const { cart } = useShoppingCart()
    // const navigate = useNavigate()

    // function showCart() {
    //     navigate(`/cart`)
    // }
    const Component = matches
        ? ActionIconsContainerMobile
        : ActionIconsContainerDesktop

    return (
        <Component>
            <MyList type="row">
                <Divider orientation="vertical" flexItem />
                <ListItemButton
                    // onClick={() => showCart()}
                    component={RouterLink}
                    to="/cart"
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: matches && Colors.companyColors.eb_bkgrnd,
                        }}
                    >
                        <Badge
                            badgeContent={cart && cart.length}
                            color="warning"
                            // sx={{ color: Colors.companyColors.eb_bolt }}
                        >
                            <ShoppingCartIcon />
                        </Badge>
                    </ListItemIcon>
                </ListItemButton>
                <Divider orientation="vertical" flexItem />
                {/* <ListItemButton
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: matches && Colors.secondary,
                        }}
                    >
                        <FavoriteIcon />
                    </ListItemIcon>
                </ListItemButton>
                <Divider orientation="vertical" flexItem />
                <ListItemButton
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: matches && Colors.secondary,
                        }}
                    >
                        <PersonIcon />
                    </ListItemIcon>
                </ListItemButton>
                <Divider orientation="vertical" flexItem /> */}
            </MyList>
        </Component>
    )
}
