import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { Box, Paper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import { Colors } from '../../../styles/theme'

// TODO: 06/06/24 *** add the feature that allows for a button hold and then it continues to increase/decrease.  buttonhold in \\wsl.localhost\Ubuntu\home\ape\code\prod\archiving interesting
export default function IncDec({ qty, onIncrItemQty, onDecrItemQty }) {
    return (
        <Paper elevation={18} style={{ width: '110px' }}>
            <Box display="flex" sx={{ mt: 0 }}>
                <IconButton
                    sx={{
                        borderRadius: 1.0,
                        border: `2px solid ${Colors.companyColors.eb_bolt}`,
                        color: '#fff',
                        background: `${Colors.secondary}`,
                        mr: '-1.8px',
                        px: 0.8,
                        py: 0.4,
                        '&:hover': {
                            backgroundColor: `${Colors.companyColors.greenLight}`,
                        },
                    }}
                    onClick={onDecrItemQty}
                >
                    <RemoveIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    sx={{
                        border: `2px solid ${Colors.companyColors.eb_bolt}`,
                        px: 1,
                        pt: 0.5,
                        pb: 0.3,
                        lineHeight: 1,
                    }}
                >
                    {qty}
                </Typography>
                <IconButton
                    sx={{
                        borderRadius: 1.0,
                        border: `2px solid ${Colors.companyColors.eb_bolt}`,
                        color: '#fff',
                        background: `${Colors.secondary}`,
                        ml: '-1.8px',
                        py: 0.4,

                        '&:hover': {
                            backgroundColor: `${Colors.companyColors.greenLight}`,
                        },
                    }}
                    onClick={onIncrItemQty}
                >
                    <AddIcon />
                </IconButton>
            </Box>
        </Paper>
    )
}
