import React from 'react'
import { Container, Grid } from '@mui/material'

import SingleProduct from './SingleProduct'

import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import SingleProductDesktop from './SingleProductDesktop'
// import useAxios from '../../hooks/useAxios'
import { useShoppingCart } from '../../context/ShoppingCartContext'

export default function Products() {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const { data } = useShoppingCart()
    // const [data, errorMsg, isLoading] = useAxios(
    //     'http://localhost:3000/api/fence_inv.json',
    // )
    // 'https://www.horsefenceestimator.com/data/fence_inv.json',

    // if (errorMsg) {
    //     const errorParagraph = `Failed in Loading data...Error found is
    //     ${errorMsg}`
    //     return <h2>{errorParagraph}</h2>
    // }
    // if (isLoading) {
    //     return <h2>Loading data...</h2>
    // }

    const renderProducts = data
        .sort((p1, pt2) => (p1.displayOrder < pt2.displayOrder ? -1 : 1))
        .filter(({ onHandQty }) => onHandQty > 0)
        .map((product) => (
            <Grid
                item
                key={product.productNum}
                xs={2}
                sm={4}
                md={4}
                display="flex"
                flexDirection={'column'}
                alignItems="center"
            >
                {matches ? (
                    <SingleProduct product={product} matches={matches} />
                ) : (
                    <SingleProductDesktop product={product} matches={matches} />
                )}
            </Grid>
        ))

    return (
        <Container>
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                justifyContent="center"
                sx={{ margin: `20px 4px 10px 4px` }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {renderProducts}
            </Grid>
        </Container>
    )
}
