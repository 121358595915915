import React from 'react'
import { Stack } from '@mui/material'
import { Item } from './Item.js'
import { appUtilities } from '../utilities/appUtilities.js'
export default function PageFooter() {
    const copyright = '\u{00A9}'
    const regTrademark = appUtilities.regTrademark

    const copyrightText = `${copyright} Copyright `
    const companyName1 = ` ElectroBraid`

    const companyName3 = ` Fence`

    return (
        <Stack justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
            <Stack>
                <Item>
                    {copyrightText}{' '}
                    <b>
                        {companyName1}
                        {regTrademark}
                        {companyName3}
                    </b>
                </Item>
            </Stack>
        </Stack>
    )
}
