import React from 'react'
import { ListItemText, Stack, Paper, Typography, Link } from '@mui/material'
import { AppbarContainer, MyList } from '../../styles/appbar'
import Actions from './actions'
import { Colors } from '../../styles/theme'
const sx_listItemAlign = { textAlign: 'center' }
import { Link as RouterLink } from 'react-router-dom'
export default function AppbarDesktop({ matches }) {
    return (
        <AppbarContainer>
            {/* <AppbarHeader variant="h4">ElectroBraid</AppbarHeader> */}
            {/* <AppbarImage src="/shop/images/appbar/logo.png" /> */}
            <Stack direction="row">
                <Paper elevation={2} sx={{ mt: 2, mb: 3, ml: -1 }}>
                    <img src="/shop/images/appbar/logo.png" />
                </Paper>
            </Stack>
            <MyList type="row">
                {/* <ListItemText  primary="Home" /> */}

                <ListItemText sx={sx_listItemAlign}>
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            href="https://www.electrobraid.com/"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="Visit front page"
                        >
                            Home
                        </Link>
                    </Typography>
                </ListItemText>
                <ListItemText sx={sx_listItemAlign}>
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            href="https://www.electrobraid.com/calc"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="Use the Fence Materials Estimator App"
                        >
                            Fence Materials Estimator
                        </Link>
                    </Typography>
                </ListItemText>

                <ListItemText sx={sx_listItemAlign}>
                    {/* <a href="#products">Products</a>*/}
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            component={RouterLink}
                            to="/"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="Visit Products section"
                        >
                            Products
                        </Link>

                        {/* #example {
  scroll-behavior: smooth;
} */}
                    </Typography>
                </ListItemText>
                {/* <ListItemText sx={sx_listItemAlign} primary="About us" /> */}

                <ListItemText sx={sx_listItemAlign}>
                    <Typography lineHeight={2} variant="body1">
                        <Link
                            component={RouterLink}
                            to="/info/contact_us"
                            color={Colors.shaft}
                            underline="none"
                            aria-label="How to Contact Us"
                        >
                            Contact Us
                        </Link>
                    </Typography>
                </ListItemText>

                {/* <ListItemButton onClick={() => setShowSearchBox(true)}>
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                </ListItemButton> */}
            </MyList>
            <Actions matches={matches} />
        </AppbarContainer>
    )
}
