import React from 'react'

import { Route, Routes } from 'react-router-dom'
import Landing from './pages/landing.js'
import NoMatch from './pages/noMatch.js'
import Shop from './pages/shop.js'
import { ShoppingCart } from './pages/ShoppingCart.js'
import CheckoutSuccess from './pages/success.js'

import { ShoppingCartProvider } from './context/ShoppingCartContext.js'
import BoilerPlateArray from 'pages/boilerPlateArray.js'
function App() {
    return (
        <ShoppingCartProvider>
            <Routes>
                <Route path="/" element={<Landing />}>
                    <Route index element={<Shop />} />
                    <Route path="cart" element={<ShoppingCart />} />
                    <Route
                        path="info/:infoType"
                        element={<BoilerPlateArray />}
                    />

                    <Route path="pg_success" element={<CheckoutSuccess />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
                <Route path="*" element={<NoMatch />} />
            </Routes>
        </ShoppingCartProvider>
    )
}

export default App
