import React from 'react'
import { AppbarContainer } from '../../styles/appbar'
import MenuIcon from '@mui/icons-material/Menu'
import Actions from './actions'
import { IconButton } from '@mui/material'
import { useUIContext } from '../../context/ui'

export default function AppbarMobile({ matches }) {
    const { setDrawerOpen } = useUIContext()
    return (
        <AppbarContainer>
            <IconButton onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
            </IconButton>

            <Actions matches={matches} />
        </AppbarContainer>
    )
}
