import React from 'react'

import { Paper } from '@mui/material'
import {
    BannerContainer,
    BannerContent,
    BannerImage,
    BannerTitle,
    BannerDescription,
} from '../../styles/banner'

export default function Banner() {
    return (
        <Paper elevation={14}>
            <BannerContainer>
                <BannerImage src="/shop/images/banner/banner.png" />
                <BannerContent>
                    <BannerDescription variant="h6">
                        An ingeniously simple permanent electric fence.
                    </BannerDescription>
                    <BannerTitle variant="h2">Safe, Secure, Simple</BannerTitle>

                    <BannerDescription variant="h6">
                        A 25 Year old company with a 25 year Warranty.
                    </BannerDescription>

                    <BannerDescription variant="h6">
                        Safe. Easy to Install. Almost No Maintenance.
                    </BannerDescription>
                </BannerContent>
            </BannerContainer>
        </Paper>
    )
}
