import React from 'react'

import Appbar from '../components/appbar/Appbar'
import { ThemeProvider } from '@mui/system'
import theme, { Colors } from '../styles/theme/'
import Products from '../components/products/Products'
import GotoCart from '../components/GotoCart'
import Banner from '../components/banner'
import { Box, Container, Typography, Stack, Divider } from '@mui/material'
import { UIProvider } from '../context/ui/'
import Footer from '../components/footer'
import AppDrawer from '../components/drawer'
import PageFooter from '../components/PageFooter.js'

export default function Shop() {
    return (
        <ThemeProvider theme={theme}>
            <Container
                sx={{
                    // marginTop: { xs: 4, md: 8 },
                    background: theme.palette.primary.main,
                    border: `solid 1px ${Colors.secondary}`,
                }}
            >
                <Stack>
                    <UIProvider>
                        <Appbar />
                        <Banner />

                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{ p: 6 }}
                        >
                            <div id="products">
                                <Typography
                                    id="section1"
                                    // variant="h4"
                                    sx={{
                                        color: '#b8956f',
                                        fontSize: '3.5rem',
                                        fontWeight: 700,
                                        // fontFamily: 'Roboto',
                                        textAlign: 'center',
                                        letterSpacing: '2px',
                                        // lineHeight: '2rem',
                                        // textTransform: 'uppercase',
                                        border: '1.75px solid #0a6e61',
                                        borderRadius: '8px',
                                        textShadow:
                                            '1px 1px 1px #005247, 1px 1px 1px #005247 ',
                                        // width: 'fit-content',
                                        backgroundColor: '#fef4e9',
                                        padding: '1rem 4rem',
                                    }}
                                >
                                    Our Products
                                </Typography>
                            </div>
                        </Box>
                        <Divider
                            orientation="horizontal"
                            variant="top"
                            flexItem
                            sx={{ marginBottom: '1.4rem' }}
                        />
                        <Products />

                        <GotoCart />

                        <Footer />
                        <AppDrawer />
                    </UIProvider>
                </Stack>

                <PageFooter />
            </Container>
        </ThemeProvider>
    )
}

// perhaps https://codesandbox.io/p/sandbox/48319372-changing-font-family-of-all-material-uiversion-1-components-7o9qe?file=%2Fdemo.js%3A60%2C31
