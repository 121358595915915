import { createTheme } from '@mui/material/styles'
// import { darken, lighten } from "polished";

export const DrawerWidth = 250

export const Colors = {
    primary: '#ffe9cf',
    secondary: '#005247',
    success: '#4CAF50',
    info: '#00a2ff',
    danger: '#FF5722',
    warning: '#ffe9cf',
    dark: '#0e1b20',
    light: '#aaa',
    muted: '#abafb3',
    border: '#DDDFE1',
    inverse: '#2F3D4A',
    shaft: '#333',
    contrastTextBlack: '#000',
    //EBF Colors
    companyColors: {
        eb_text: '#004F39',
        eb_bkgrnd: '#fef4e9',
        eb_bolt: '#B98647',
        eb_bolt2: '#B19470',

        eb_text_service: '#43766C',
        eb_bkgrnd_service: '#F8FAE5',
        eb_bolt_service: '#B19470',
        eb_bolt_service2: '#76453B',
        eb_bolt_service3: '#76453B',

        accent: '#800000',

        greenLight: '#76af8a',
        greenDark: '#006e64',
        contrastTextWhite: '#FEFDED',

        beige: '#ffe9cf',
        brown: '#825735',
        orange: '#d87c28',
        orangeL1: '#f6c995',
        orangeL2: '#ffe9cf',
        copper: '#b87333',

        yellow: '#fff179',
        cream: '#fef4e9',
        red: '#800000',
        redL: '#edc1c4',
    },
    ///////////////
    // Grays
    ///////////////
    dim_grey: '#696969',
    dove_gray: '#d5d5d5',
    body_bg: '#f3f6f9',
    light_gray: 'rgb(230,230,230)',
    ///////////////
    // Solid Color
    ///////////////
    white: '#fff',
    black: '#000',
}

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.primary,
            light: Colors.primary,
            dark: Colors.primary,
        },
        secondary: {
            main: Colors.secondary,
        },
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },

    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    background: Colors.secondary,
                    color: 'white',
                    border: `2px solid ${Colors.companyColors.eb_bolt}`,
                    '&:hover': {
                        backgroundColor: `${Colors.companyColors.greenLight}`,
                    },
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    background: Colors.primary,
                },
                arrow: {
                    color: Colors.primary,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: DrawerWidth,
                    background: Colors.primary,
                    color: Colors.secondary,
                    borderRadius: '0px 100px 0px 0px',
                    borderRight: `1px solid ${Colors.primary}`,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    // borderColor: lighten(0.2, Colors.primary),
                },
            },
        },
        MyShopButton: {
            styleOverrides: {
                root: {
                    color: Colors.white,
                },
                primary: {
                    background: `${Colors.secondary}`,
                    '&:hover': {
                        // background: lighten(0.05, Colors.primary),
                    },
                },
                secondary: {
                    background: `${Colors.primary}`,
                    '&:hover': {
                        // background: lighten(0.05, Colors.primary),
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    padding: '2px 3px',
                    borderRadius: '4px',
                    textDecoration: 'none',
                    ':hover': {
                        textDecoration: 'underline',
                        fontSize: '103%' /* Increase font size on hover */,
                        backgroundColor: `${Colors.secondary}`,
                        color: `${Colors.primary}`,
                        fontWeight: '500',
                    },
                },
            },
        },
    },
})

export default theme
