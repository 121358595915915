import React from 'react'

import {
    Grid,
    List,
    ListItemText,
    Typography,
    Stack,
    Link,
} from '@mui/material'
import { Box } from '@mui/system'
import { Colors } from '../../styles/theme'
import { FooterTitle } from '../../styles/footer'
import { Link as RouterLink } from 'react-router-dom'
export default function Footer() {
    return (
        <Box
            sx={{
                background: Colors.shaft,
                color: Colors.white,
                p: { xs: 4, md: 5 },
                mt: 2,
                fontSize: { xs: '12px', md: '14px' },
            }}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={6}
                    md={3}
                    sx={{
                        justifyContent: { sm: 'center', md: 'flex-Start' },
                    }}
                >
                    <Grid sx={{ ml: { xs: 4, sm: 12, md: 0 } }}>
                        <FooterTitle variant="body1">information</FooterTitle>
                        <List>
                            <ListItemText>
                                <Typography lineHeight={2} variant="caption2">
                                    <Link
                                        href="https://www.electrobraid.com/privacy.html"
                                        color="primary"
                                        underline="none"
                                        aria-label="Read about Privacy Policy"
                                    >
                                        Privacy &amp; Policy
                                    </Link>
                                </Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography lineHeight={2} variant="caption2">
                                    <Link
                                        component={RouterLink}
                                        to="/info/terms_conditions"
                                        color="primary"
                                        underline="none"
                                        aria-label="Learn about Terms and Conditions"
                                    >
                                        Terms &amp; Conditions
                                    </Link>
                                </Typography>
                            </ListItemText>
                        </List>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                    sx={{
                        justifyContent: { sm: 'center', md: 'flex-Start' },
                    }}
                >
                    <Grid sx={{ ml: { xs: 4, sm: 12, md: 0 } }}>
                        <FooterTitle variant="body1">Shop</FooterTitle>
                        <List>
                            <ListItemText>
                                <Typography lineHeight={2} variant="caption2">
                                    <Link
                                        component={RouterLink}
                                        to="/cart"
                                        underline="none"
                                        aria-label="Visit your cart items"
                                    >
                                        Cart Review
                                    </Link>
                                </Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography lineHeight={2} variant="caption2">
                                    <Link
                                        component={RouterLink}
                                        to="/info/shipping"
                                        underline="none"
                                        aria-label="Learn about Shipping Policies"
                                    >
                                        Shipping Policy
                                    </Link>
                                </Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography lineHeight={2} variant="caption2">
                                    <Link
                                        component={RouterLink}
                                        to="/info/return_policy"
                                        color="primary"
                                        underline="none"
                                        aria-label="Learn about Return Policy"
                                    >
                                        Return Policy
                                    </Link>
                                </Typography>
                            </ListItemText>
                        </List>
                    </Grid>
                </Grid>{' '}
                <Grid
                    item
                    xs={12}
                    md={6}
                    display={'flex'}
                    justifyContent={'center'}
                    sx={{ mt: { xs: 6, sm: 2, md: 0 } }}
                >
                    <Box>
                        <FooterTitle variant="body1">
                            Cardholder Data Security
                        </FooterTitle>
                        <List>
                            <ListItemText>
                                <Typography lineHeight={2} variant="caption2">
                                    <Link
                                        component={RouterLink}
                                        to="/info/card_security"
                                        color="primary"
                                        underline="none"
                                        aria-label="Learn about Terms and Conditions"
                                    >
                                        Cardholder Security Policy
                                    </Link>
                                </Typography>
                            </ListItemText>
                        </List>
                        <Stack direction={'row'}>
                            <Box sx={{ mt: { xs: 3, md: 5 } }}>
                                <div className="AuthorizeNetSeal">
                                    <a
                                        href="https://www.authorize.net/"
                                        title="Secure payment acceptance. A Visa Solution"
                                    >
                                        <img
                                            src="/shop/images/secure90x72.png"
                                            alt="Authorize.Net Payment Solution Provider"
                                        />
                                    </a>
                                </div>
                            </Box>

                            <Box sx={{ ml: 9, mt: { xs: 5, md: 7 } }}>
                                <a
                                    href="http://www.foxycart.com/"
                                    title="We chose FoxyCart for easy, SEO ecommerce"
                                >
                                    <img
                                        src="//cdn.foxycart.com/static/images/foxycart_logos/powered-by-foxycart-108x46.png"
                                        alt="Powered by FoxyCart Ecommerce"
                                    />
                                </a>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
